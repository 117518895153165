import React, { FunctionComponent, Fragment } from 'react'
import { graphql, PageProps } from 'gatsby'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'

import { css } from 'styled-components'
import GatsbyImage, { FixedObject } from 'gatsby-image'

import { ContentfulText } from '~/types/graphql'

import Page from '~/components/Page'
import MarkdownHTML from '~/components/MarkdownHTML'

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...LayoutData
		# ...FooterData

		intro: contentfulText(contentfulid: { eq: "intro" }, node_locale: { eq: $locale }) {
			content {
				childMarkdownRemark {
					html
				}
			}
		}

		portrait: file(relativePath: { eq: "images/portrait10.jpg" }) {
			childImageSharp {
				fixed(width: 200, height: 200) {
					...GatsbyImageSharpFixed_withWebp
				}
			}
		}
	}
`

type Props = {
	data: {
		intro: ContentfulText
		portrait: {
			childImageSharp: {
				fixed: FixedObject
			}
		}
	}
} & PageProps

const IndexPage: FunctionComponent<Props> = ({ data, location, pageContext, ...rest }) => {
	const { intro, portrait, portrait2 } = data

	return (
		<Page>
			<Container maxWidth="md">
				<Box mt={6} mb={6}>
					<Fade in>
						<MarkdownHTML html={intro.content.childMarkdownRemark.html} />
					</Fade>
				</Box>
			</Container>
			<Box textAlign="center" py={5}>
				<Fade in>
					<GatsbyImage
						css={css`
							display: inline-block;
							transform: rotate(45deg);
						`}
						fixed={portrait.childImageSharp.fixed}
					/>
				</Fade>
			</Box>
		</Page>
	)
}

export default IndexPage
